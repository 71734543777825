import React from 'react';
import NavBar from './Components/Navagation';
import Jumbo from './Components/Jumbotron';
import SectionOne from './Components/Intro';
import ModeBoard from './Components/ModalBoard';
import ServiceList from './Components/ServiceList';
import Testimonials from './Components/TestiSlider';
import AboutSection from './Components/AboutSection';
import Footer from './Components/Footer';
import { findRenderedDOMComponentWithClass } from 'react-dom/test-utils';
import './Components/tca-font.css';


function App() {
  return (
    <div className="">
      <NavBar />
      <Jumbo />
      <SectionOne />
      <ModeBoard />
      <ServiceList />
      <Testimonials />
      <AboutSection />
      <Footer />
    </div>
  );
}

export default App;
