import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function ManufModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Manufacturing Accounting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                <li><b>WIP</b> – Determining what work is still in progress and what is finished goods.</li>
                <li><b>Inventory Valuation</b> – Using costs to determine the value of inventory.</li>
                <li><b>Cost of Goods Sold</b> – Finding the direct costs to produce the goods including material and labor directly used to create the good.</li>
                <li><b>Job Tracking</b> – Tracking a job through each stage of production.</li>
            </ul> 
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  


  export default ManufModal;