import React from 'react';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import ContactModal from './ContactModal';
import LogoImg from '../Assets/TCA-Logo-Final.svg';
import './nav.css';

const NavBar = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <div id="nav-top" className="nav-back">
        <Navbar expand="lg" className="container" >
          <Navbar.Brand href="#home"><img src={LogoImg}
                width="auto"
                height="52"
                className="d-inline-block align-top"
                alt="Theresa Carlson Accounting logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="ml-auto justify-content-end">
              <Nav.Link href="#services">Services</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link onClick={() => setModalShow(true)}>Contact</Nav.Link>
              <ContactModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="nav-contact-back">
        <div className=" container under-nav">
            <a href="mailto:carlsonacct@hotmail.com">carlsonacct@hotmail.com</a>
            <p>916-805-4034</p>
        </div>
    </div>
</div>
)
}

export default NavBar;