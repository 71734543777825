import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function TechModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Tech Accounting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                <li><b>Project Planning</b> – Organizing different areas of a project involving all members of the team.</li>
                <li><b>Cost Audits</b> – Analysis and reporting all costs involved with a project.</li>
                <li><b>Workflow</b> – Moving each project from initiation to completion.</li>
                <li><b>Time Tracking</b> – Tracking hours on projects while determining if they are billable or non-billable hours then billing clients for hours worked.</li>
            </ul> 
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  


  export default TechModal;