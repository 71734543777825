import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MoneyIcon from '../Assets/money-icon.svg';
import './intro.css';

const SectionOne = () => (
    <Container className="tca-intro">
        <Row>
            <Col lg={{span:3, offset:1}}><img src={MoneyIcon} alt="Money icon" width="261px" /></Col>
    
            <Col lg={{span:6, offset:2}}>
                <h1>When It Comes To Full Service Accounting & Tax Prep</h1>
                <h3>Smart Business Trust Thresa Carlson E.A.</h3>
                <p>For over 20 years Theresa Carlson has been providing outstanding accounting, bookkeeping and payroll services to small and mid–sized businesses. Services include business start up, business accounting, computer system set up and training and forensic accounting. We can handle all of you tax preparation and payroll. Set up to serve you remotely or we can visit you in person.</p>
            </Col>
        </Row>
    </Container>
)

export default SectionOne;