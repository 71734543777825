import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function RetailModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Retail Accounting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                <li><b>Sales Tax</b> – Tracking taxable and nontaxable sales items and reporting to the correct taxing agency.</li>
                <li><b>Point of Sale</b> –  Setting up POS systems for clients and reconciling end of day receipts.</li>
                <li><b>Inventory Valuation</b> – Determining which inventory valuation is better for the company, FIFO, LIFO or Average cost.</li>
                <li><b>Payroll</b> – Handling all aspects of payroll from entering new employees to paying payroll taxes.</li>
            </ul> 
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  


  export default RetailModal;