import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';




function ContactModal (props) {
     return (
         <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Contact
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Please Feel Free to give us a call betwwen 8am-6pm Monday through Fiday.</p>
              <p><b>916-805-4034</b></p>
              <p>Or if it is after hours or more convenient you can email us at:</p>
              <p><a href="mailto:carlsonacct@hotmail.com"><b>carlsonacct@hotmail.com</b></a></p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        );
      }
      



export default ContactModal;