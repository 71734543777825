import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import './footer.css';
import MailLogo from '../Assets/Mail-icon.png';
import LinkLogo from '../Assets/linkedin.png';
import UpArrow from '../Assets/up-arrow.png';


const Footer = () => (
    <div className="footer-wrap">
        <Container>
            <Row>
                <Col className="footer-center">
                    <a href="#nav-top"><img src={UpArrow} alt="" /></a>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <p>Theresa Carlson Accounting</p>
                    <p>Folsom, CA</p>
                    <p>916-805-4034</p>
                    <p>Monday - Friday 8am to 6pm</p>
                </Col>
                <Col sm={4}></Col>
                <Col sm={2} className="footer-links">
                    <a href="mailto: "><img src={MailLogo} alt="" /></a>
                    <a href="https://www.linkedin.com/in/theresa-carlson-ea-b84a473b/"><img src={LinkLogo} alt="" /></a>
                </Col>
            </Row>
        </Container>
    </div>
)


export default Footer;