import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function AutoModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Automotive Accounting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                <li><b>Sales Tax</b> – Tracking taxable and nontaxable sales items and reporting to the correct taxing agency.</li>
                <li><b>Purchase Orders</b> –  Entering and receiving purchase orders for customer’s orders.</li>
                <li><b>Class Accounting</b> – Separating revenue and expenses based on department in the dealership.</li>
                <li><b>Inventory</b> – Controlling and tracking inventory.</li>
            </ul> 
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  


  export default AutoModal;