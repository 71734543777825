import React from 'react'; 
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const ServiceList = () => {
    const top = {
        paddingTop: '3.5em',
        paddingBottom: '3.5em'
    };
    return (
    <Container style={top} id="services">
    <Row>
        <Col sm={6} className="text-center">
            <h2>Start-up Services</h2>
            <ul className="list-unstyled">
                <li>Business Plan</li>
                <li>Business Licenses and Permits</li>
                <li>Capital Start-up</li>
                <li>Business Structure</li>
                <li>Federal and State Tax ID number</li>
                <li>Payroll Set-up</li>
                <li>Software</li>
                <li>Insurance</li>
            </ul>
        </Col>
        <Col sm={6} className="text-center">
            <h2>Computer Services</h2>
            <ul className="list-unstyled text-center">
                <li>QuickBooks setup</li>
                <li>QuickBooks cleanup</li>
                <li>Peachtree setup</li>
                <li>Peachtree training</li>
                <li>Lightspeed setup all departments</li>
                <li>Lightspeed cleanup all departments</li>
                <li>QuickBooks training</li>
                <li>QuickBooks cleanup</li>
                <li>Peachtree cleanup</li>
                <li>Lightspeed training all departments</li>
                <li>Conversion to the cloud</li>
            </ul>
        </Col>
    </Row>
    </Container>
);
    };


export default ServiceList;