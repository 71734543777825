import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './jumbo.css';


const Jumbo = () =>(
    <Jumbotron>
        <div className="container">
             <h1>Professional Buisness</h1>
             <h1>&amp; Accounting Services</h1>
             <p>
                 Over 20 years of experience searving Californian's accounting needs
             </p>
        </div>
     </Jumbotron>
    
)

export default Jumbo;