import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function EcomModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Ecommerce Accounting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                <li><b>Web site sales</b> – Entering sales from multiple different web site sales platforms.</li>
                <li><b>Virtual Shopping Cart</b> –  Tracking sales tax from virtual shopping cart to report taxable sales in the correct state.</li>
                <li><b>Inventory</b> – Controlling and tracking inventory.</li>
                <li><b>Merchant Services</b> – Recording payments from merchant sites.</li>
            </ul> 
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  


  export default EcomModal;