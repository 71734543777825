import React from 'react';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import LostLogo from '../Assets/logo.png';
import FremontLogo from '../Assets/logonew.png';
import GoletaLogo from '../Assets/Goleta-Logo.png';
import './testislider.css';

const Testimonials = () =>(
    <Container>
        <h2 class="text-center">Testimonials</h2>
          <div class="lt-blue-square">
            <div class="dk-blue-square">
            </div>
          </div>
        <Carousel>
            <Carousel.Item>
            <div className="row justify-content-between">
                <img src={LostLogo} alt="" className="col-lg-3" />
                  <div className="col-lg-7 testimonial-wraper shadow-lg">
                    <h3>Emma Thomson Owner,<br />Lost Campers Inc.</h3>
                    <a href="https://www.lostcampersusa.com/">lostcampersusa.com</a>
                    <p>“ Theresa is an absolute godsend and we are so pleased she is part of our team. For many years, I was looking after our company's books and was a little frightened to hand them over to anyone else. As our business increased, I knew I needed help and in walked Theresa. She effortlessly took over, made many improvements and always provides an excellent and efficient service. You're in the right place if you are looking for a skilled book keeper.”</p>
                  </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <div class="row justify-content-between">
                  <img src={FremontLogo} alt="" class="col-lg-4" />
                  <div class="col-lg-7 testimonial-wraper shadow">
                    <h3>Eddie Benson Owner, Fremont Honda Kawasaki Sukuki</h3>
                    <a href="https://fremonthondakawasaki.com/" target="_blank" rel="noopener noreferrer">fremonthondakawasaki.com</a>
                    <p>“Theresa Carlson of Carlson Accounting has contracted for me for over 4 yrs. She has been great to deal with. I am able to contact her at anytime by phone, text & email. She is always there to assist or answer any of my questions at anytime. She is very knowledgeable. If you are looking for an accountant that you can count on Carlson Accounting is who want. ”</p>
                  </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <div class="row justify-content-between">
                  <img src={GoletaLogo} alt="" class="col-lg-4" />
                  <div class="col-lg-7 testimonial-wraper shadow">
                    <h3>Danny Topete Owner, Goleta Muffler and Brake</h3>
                    <a href="https://goletamufflerandbrake.com/" target="_blank" rel="noopener noreferrer">goletamufflerandbrake.com</a>
                    <p>“ Theresa is an absolute godsend and we are so pleased she is part of our team. For many years, I was looking after our company's books and was a little frightened to hand them over to anyone else. As our business increased, I knew I needed help and in walked Theresa. She effortlessly took over, made many improvements and always provides an excellent and efficient service. You're in the right place if you are looking for a skilled book keeper.”</p>
                  </div>
                </div>
            </Carousel.Item>
        </Carousel>
    </Container>
)


export default Testimonials;