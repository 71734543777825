import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import HeadShot from '../Assets/Teri-Headshot-left.png';
import EaLogo from '../Assets/ea-logo.jpg';
import PtinSeal from '../Assets/ptin-seal.png';
import TurboTax from '../Assets/turbotax.png';
import QuicBooks from '../Assets/quicbooks-logo.png';
import LightSpeed from '../Assets/lightspeed-logo.png';
import TaxAct from '../Assets/tax-act.png';
import './aboutsection.css';

const AboutSection = () => (
    <Container className="about-area">
        <section class="space-fix  slideanim" id="about">
            <h2 class="text-center p-3">About Theresa Carlson, EA</h2>
            <div class="about-wraper row justify-content-between">
            <div class="about-paragraph col-lg-6">
                <p class="pt-5">For over 20 years Theresa Carlson has been providing outstanding accounting services to small and medium sized businesses. A graduate of the University of California, Santa Barbara with a degree in Economics with an emphasis in Accounting, Theresa has honed her skills as an employee of a wide range of industries. She has worked as the controller for the largest motorcycle dealership in the Santa Barbara area as well as non-profit, restaurant and housing establishments.</p>
                <p>In 2003, after increased demand for her services, Theresa decided to pool her knowledge and resources forming Theresa Carlson Accounting. Since its inception Theresa Carlson has served clients, both in person and remotely, across the greater West Coast. Theresa had found great pleasure in helping clients with their business accounting needs. She has stated that, “numbers are like a puzzle, each one has a place and only when they all fit can you get a sense for how a business is doing.”</p>
                <h5>Theresa has Enrolled Agent status, and is licenced to represent clients before the IRS</h5>
                <img src={EaLogo} alt="Enroled Agent logo" height="90" width="auto" className="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src={HeadShot} alt="" width="100%" />
                <div class="sm-square">
                </div>
            </div>
            </div>
        </section>
        <div className="about-badges">
            <Row>
                <Col>
                    <Image src={PtinSeal} alt="" fluid />
                </Col>
                <Col>
                    <Image src={TurboTax} alt="" fluid />
                </Col>
                <Col>
                    <Image src={QuicBooks} alt="" fluid />
                </Col>
                <Col>
                    <Image src={LightSpeed} alt="" fluid />
                </Col>
                <Col>
                    <Image src={TaxAct} alt="" fluid />
                </Col>
            </Row>
        </div>
    </Container>

)

export default AboutSection;