import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import ModalBox from './Themodal';
import DesignModal from './DesignModal';
import ManufModal from './ManufacturingModal';
import TechModal from './TechModal';
import RetailModal from './RetailModal';
import AutoModal from './AutoModal';
import EcomModal from './EcomModal';
import './modeboard.css';

const ModeBoard = () => {
    const [dModalShow, setDModalShow] = React.useState(false);
    const [mModalShow, setMModalShow] = React.useState(false);
    const [tModalShow, setTModalShow] = React.useState(false);
    const [rModalShow, setRModalShow] = React.useState(false);
    const [aModalShow, setAModalShow] = React.useState(false);
    const [eModalShow, setEModalShow] = React.useState(false);
    
    return(
    <section className="mode-wrap">
        <div className="container">
            <h2 className="text-center">Experience For Your Business</h2>
        </div>
        <Container>
            <Row xs={1} sm={3}>
                <Col>
                    <DesignModal />
                    <button className="modal-button design-modal" variant="primary" onClick={() => setDModalShow(true)}>
                        <h3>Design</h3>
                    </button>

                    <DesignModal
                        show={dModalShow}
                        onHide={() => setDModalShow(false)}
                    />
                </Col>
                <Col>
                    
                    <ManufModal heading="Heading two" test="test two" />
                        <button className="modal-button manufacture-modal" variant="primary" onClick={() => setMModalShow(true)}>
                            <h3>Manufacturing</h3>
                        </button>

                    <ManufModal 
                        show={mModalShow}
                        onHide={() => setMModalShow(false)}
                    />
                </Col>
                <Col>
                     <TechModal heading="Heading two" test="test two" />
                        <button className="modal-button tech-modal" variant="primary" onClick={() => setTModalShow(true)}>
                            <h3>Tech</h3>
                        </button>

                    <TechModal 
                        show={tModalShow}
                        onHide={() => setTModalShow(false)}
                    />
                </Col>
            </Row>
            <Row xs={1} sm={3}>
                <Col>
                    <RetailModal heading="Heading two" test="test two" />
                        <button className="modal-button retail-modal" variant="primary" onClick={() => setRModalShow(true)}>
                            <h3>Retail</h3>
                        </button>

                    <RetailModal 
                        show={rModalShow}
                        onHide={() => setRModalShow(false)}
                    />
                </Col>
                <Col>
                    <AutoModal heading="Heading two" test="test two" />
                        <button className="modal-button auto-modal" variant="primary" onClick={() => setAModalShow(true)}>
                            <h3>Automotive</h3>
                        </button>

                    <AutoModal 
                        show={aModalShow}
                        onHide={() => setAModalShow(false)}
                    />
                </Col>
                <Col>
                    
                    <EcomModal heading="Heading two" test="test two" />
                        <button className="modal-button ecom-modal" variant="primary" onClick={() => setEModalShow(true)}>
                            <h3>Ecommerce</h3>
                        </button>

                    <EcomModal 
                        show={eModalShow}
                        onHide={() => setEModalShow(false)}
                    />
                </Col>
            </Row>
        </Container>
    </section>
    );
}

export default ModeBoard;