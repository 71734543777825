import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function DesignModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Design Accounting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                <li><b>Time Tracking</b> – Tracking hours on projects while determining if they are billable or non-billable hours then billing clients for hours worked.</li>
                <li><b>Purchase Orders</b> – Entering and receiving purchase orders for client product.</li>
                <li><b>Job Profitability</b> – Determining profitability based on each individual jobs.</li>
                <li><b>WIP</b> – Determining what work is still in progress.</li>
            </ul> 
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  


  export default DesignModal;